.herobanner-full {
  &__list {
    position: relative;
    .wrapper {
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    &__image {
      position: relative;
      &::before,
      &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        top: 0;
        left: 0;
      }
      &::before {
        background: linear-gradient(
          89.56deg,
          #000000 32.39%,
          rgba(0, 0, 0, 0) 73.75%
        );
        opacity: 0.5;
      }
      &::after {
        background: var(--c-neutral-black);
        opacity: 0.4;
      }
      img,
      video {
        height: 100vh;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &__text {
      max-width: 538px;
      color: var(--c-neutral-white);
      h6 {
        color: var(--c-primary-main);
        margin-bottom: 4px;
      }
      h2 {
        line-height: 1.2;
      }
      p {
        font-size: 1.25rem;
        margin: 0;
        line-height: 1.4;
      }
      .button-group {
        margin-top: 54px;
        .button {
          margin-right: 22px;
					&:last-child {
						margin-right: 0; 
					}
        }
        .button-outline {
          border-color: var(--c-neutral-white);
          color: var(--c-neutral-white);
          &:hover {
            background: var(--c-secondary-hover);
            border-color: var(--c-secondary-hover);
          }
        }
      }
    }
    &.video {
      .herobanner-full__list__image {
        &:before,
        &:after {
          display: none;
        }
      }
    }
  }
	.swiper-wrapper {
		height: auto; 
	}
  .swiper-arrow {
    transform: translateY(-50%);
    &.swiper-button-next {
      right: calc((100vw - 1200px) / 2);
      margin-right: -76px;
    }
    &.swiper-button-prev {
      left: calc((100vw - 1200px) / 2);
      margin-left: -76px;
    }
    .wa-ic-chevron-left-outline,
    .wa-ic-chevron-right-outline {
      font-size: 40px;
      color: var(--c-neutral-white);
    }
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after,
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    display: none;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 104px;
    width: 1200px;
    height: 7px;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
    line-height: 0;
  }
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    width: 40px;
    height: 4px;
    border-radius: 0;
    background: var(--c-neutral-white);
    opacity: 0.4;
    margin: 0 16px 0 0;
    transition: all 0.2s ease-out;
  }
  .swiper-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet-active,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet-active {
    background: var(--c-secondary-main);
    width: 64px;
  }

  &.half {
    .herobanner-full__list {
      &__image {
        img {
          height: auto;
        }
      }
      &__text {
        h2 {
          margin-bottom: 13px;
        }
				.button-group {
					margin-top: 32px; 
				}
      }
    }
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 54px;
    }
  }
}


/* responsive desktop
----------------------------------------------------------------------------------------------*/
@media (max-width: 1600px) and (max-height: 800px) {
  .herobanner-full .swiper-horizontal > .swiper-pagination-bullets, .herobanner-full .swiper-pagination-bullets.swiper-pagination-horizontal, .herobanner-full .swiper-pagination-custom, .herobanner-full .swiper-pagination-fraction {
    bottom: 54px;
  }
}
@media all and (max-width: 1400px) {
  .herobanner-full {
		.swiper-arrow {
			&.swiper-button-next {
				margin-right: -60px;
			}
			&.swiper-button-prev {
				margin-left: -60px;
			}
		}

    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 54px;
    }		
  }
}


/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
	.herobanner-full {
		&__list {
			.wrapper {
				max-width: none;
				width: auto;
				margin: 0 90px;
				left: 0;
				right: 0;
				transform: unset;
			}
			&__text {
				max-width: 466px;
				h2,
				h6 {
					margin-right: 10px; 
				}
				p {
					font-size: 1.125rem; 
				}
				.button-group {
					margin-top: 30px; 
				}
			}
		}
		
		.swiper-arrow {
			&.swiper-button-next {
				right: 20px;
				margin-right: 0;
			}
			&.swiper-button-prev {
				left: 20px;
				margin-left: 0;
			}    
		}
		
		.swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
			width: auto;
			left: 90px;
			right: 90px;
			transform: unset;
      bottom: 80px;
    }

		&.half {			
			.herobanner-full__list {	
				&__text {		
					.button-group {
						margin-top: 30px; 
            margin-bottom: 35px;
					}
				}		
			}
			.swiper-horizontal > .swiper-pagination-bullets,
			.swiper-pagination-bullets.swiper-pagination-horizontal,
			.swiper-pagination-custom,
			.swiper-pagination-fraction {
				bottom: 35px;
			}	
		}

	}	
}


/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
	.herobanner-full {
		&__list {
			.wrapper {
				margin: 0 70px; 
			}
			&__text {
				max-width: 628px; 
				padding: 0; 
				text-align: center; 
				margin: auto; 		
				h2,
				h6 {
					margin-right: 0; 
				}		
				p {
					margin: 0 25px; 
				}				
			}
		}

		.swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {			
			left: 70px;
			right: 70px; 
      bottom: 48px;
			text-align: center;
    }
		
		.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  	.swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
			margin: 0 8px; 
		}

		&.half {			
			.herobanner-full__list {	
				&__text {		
					.button-group {
						margin-bottom: 0; 
					}
				}		
			}
			.swiper-horizontal > .swiper-pagination-bullets,
			.swiper-pagination-bullets.swiper-pagination-horizontal,
			.swiper-pagination-custom,
			.swiper-pagination-fraction {
				bottom: 50px;
			}	
		}

	}
}


/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
	.herobanner-full {
		&__list {
			.wrapper {
				margin: 0 16px; 
			}
			&__text {
				margin-left: 20px; 
				margin-right: 20px;
				p {
					font-size: 1rem; 
					margin: 0; 
				}
				.button-group {
					margin-top: 40px;
					margin-left: -20px;
					margin-right: -20px;
					display: flex;
					justify-content: center;
					flex-wrap: wrap;
					.button {
						margin: 0 8px 8px; 
						&:last-child {
							margin-right: 8px;
						} 
					}
				}
			}
		}
	
		.swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
			left: 16px;
			right: 16px; 
			bottom: 50px; 
		}

		&.half {
			.herobanner-full__list {
				&__text {				
					.button-group {
						margin-top: 30px; 
					}
				}
			}
			

			.swiper-horizontal > .swiper-pagination-bullets,
			.swiper-pagination-bullets.swiper-pagination-horizontal,
			.swiper-pagination-custom,
			.swiper-pagination-fraction {
				bottom: 40px; 	
			}
		}

	}
}