.dropdown-icon {
  position: relative;
  display: inline-block;
  &__icon,
  &__text {
    display: block;
  }
  &__icon {
    margin-right: 8px;
    flex: 1 0 16px;
    max-width: 16px;
  }
  &__text {
    font-weight: 600;
    color: var(--c-neutral-black);
    width: 100%;
  }
  &__button {
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px 30px 8px 0;
    cursor: pointer;
    &::after {
      content: "\e930";
      font-family: var(--font-webarq-icon);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 2;
    background: var(--c-neutral-white);
    padding: 8px;
    box-shadow: var(--shadow-medium);
    &--list {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      font-size: 0.875rem;
      cursor: pointer;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &__small {
    .dropdown-icon__text {
      font-size: 0.875rem;
    }
  }
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 1200px) {}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .dropdown-icon {
    &__text {
      font-size: 0.875rem;
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
// @media all and (max-width: 767px) {}
