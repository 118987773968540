.sectionnews-aside {
  position: relative;
  padding: 80px 0;
  .row {
    justify-content: space-between;
    .sectiontext {

      h6 {
        color: var(--c-primary-main);
        margin-bottom: 8px;
      }
      p {
        color: var(--c-neutral-gray-4);
      }
    }
  }
  .sectiontext {
    .button {
      padding: 0 28px;
      box-sizing: border-box;
    }
  }
  &__list {
    &--item {
      position: relative;
      margin-bottom: 32px;
      .box {
        display: flex;
        figure {
          width: 330px;
          position: relative;
          display: block;
          overflow: hidden;
          img {
            width: 100%;
            transition: all ease-out .3s;
          }
        }
        figcaption {
          width: calc(100% - 330px);
          padding-right: 40px;
          .category-item {
            color: var(--c-primary-main);
            font-size: .875rem;
            font-weight: 700;
            display: inline-flex;
            align-items: center;
            margin-bottom: 8px;
            i {
              font-size: 20px;
              display: block;
              margin-right: 8px;

            }
          }
          h5 {
            font-weight: 600;
            color: var(--c-neutral-black);
            margin-bottom: 8px;
            font-size: 1.25rem;
          }
          p {
            margin-bottom: 8px;
            color: var(--c-neutral-gray-4);
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
          }
          .date-user {
            color: var(--c-neutral-gray-3);
            font-size: .75rem;
            line-height: 2;
            display: inline-flex;
            align-items: center;
          }
        }
        &:hover {
          figure {
            img {
              transition: all ease-out .3s;
              transform: scale(1.1);
            }
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
	.sectionnews-aside {
    .row {
      .column {
        &.column-35 {

        }
        &.column-57 {
          flex: 0 0 65%;
          max-width: 65%;
        }
      }
    }
    &__list {
      &--item {
        .box {
          figure {
            width: 280px;
          }
          figcaption {
            width: calc(100% - 280px);
            padding-right: 32px;
          }
        }
      }
    }
  }
}


/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .sectionnews-aside {
    padding: 40px 0;
    .row {
      .column {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
    .sectiontext {
      padding-right: 220px;
      position: relative;
      margin-bottom: 40px;
      h3 {
        font-size: 26px;
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 0px;

      }
      .button {
        margin-right: 0;
        position: absolute;
        top: auto;
        right: 12px;
        bottom: 0;
      }
    }
    &__list {
      &--item {
        .box {
          figure {
            width: 280px;
          }
          figcaption {
            width: calc(100% - 280px);
            padding-right: 32px;
          }
        }
      }
    }
  }
}


/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
	.sectionnews-aside {
    padding: 24px 0;
    .sectiontext {
      padding-right: 0;
      position: relative;
      margin-bottom: 40px;
      h3 {
        font-size: 20px;
        margin-bottom: 12px;
      }
      p {
        margin-bottom: 16px;

      }
      .button {
        position: relative;
        top: 0;
        right: auto;
        bottom: auto;
      }
    }
    &__list {
      &--item {
        margin-bottom: 40px;
        .box {
          flex-wrap: wrap;
          figure {
            width: 100%;
            img {
              width: 100%;
            }
          }
          figcaption {
            width: 100%;
            padding-right: 0;
            margin-bottom: 24px;
            h5 {
              font-size: 18px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
