.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all ease-out 0.2s;
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(17, 17, 17, 0.7);
  }
  &__box {
    width: 100%;
    max-width: 600px;
    background: var(--c-neutral-white);
    border-radius: 16px;
    box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 2;
    padding: 32px;
    transition: all ease-out 0.3s;
    transform: scale(0.9);
    opacity: 0;
    &--large {
      max-width: 944px;
    }
  }
  &__close {
    position: absolute;
    top: 24px;
    right: 32px;
    font-size: 24px;
    cursor: pointer;
    transform: scale(1);
    transition: 0.2s all ease-out;
    &:hover {
      transform: scale(0.9);
    }
  }
  &__content {
    &--center {
      text-align: center;
    }
    &--right {
      text-align: right;
    }
    figure {
      margin-bottom: 32px;
      img {
        width: 100%;
        max-height: 360px;
        object-fit: cover;
      }
    }
  }
  &__overflow {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 290px);
    max-height: calc(var(--vh, 1vh)*100 - 290px);
    padding-right: 30px;
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      background: var(--c-primary-surface);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--c-primary-main);
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: var(--c-primary-main);
    }
  }

  &__show {
    opacity: 1;
    visibility: visible;
    .popup {
      &__box {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
  &.cookies-wrap {
    align-items: flex-end;
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0.7);
    .popup__overflow {
      max-height: calc(100vh - 215px);
      max-height: calc(var(--vh, 1vh)*100 - 215px);
      padding-right: 15px;
      &::-webkit-scrollbar {
        width: 6px;
      }
    }
    p {
      line-height: 160%;
    }
    .popup__box {
      margin: 0 20px 20px;
    }
    .list-cookies {
      position: relative;
      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        p {
          color: var(--c-neutral-gray-5);
          margin: 0;
          font-weight: 600;
          line-height: 160%;
          width: calc(100% - 70px);
          span {
            display: block;
            font-size: .875rem;
            color: var(--c-neutral-gray-4);
            font-weight: 400;
            line-height: 1.3;
          }
        }
      }
    }
    .btn-cookies {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding-top: 24px;
      .button {
        margin-right: 12px;
        margin-bottom: 0;
        line-height: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .popup {
    &__box {
      &--large {
        max-width: 100%;
        margin: 0 40px;
      }
    }
    &__overflow {
      .row {
        flex-wrap: wrap;
        .column {
          &.column-50 {
            flex: unset;
            max-width: 100%;
          }
        }
      }
    }
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .popup {
    &__box {
      &--large {
        margin: 0 16px;
        padding: 48px 16px 16px;
      }
    }
    &__close {
      top: 16px;
      right: 17px;
    }
    &.cookies-wrap {
      p {
        line-height: 150%;
      }
      .popup__overflow {
        max-height: calc(100vh - 195px);
        max-height: calc(var(--vh, 1vh)*100 - 195px);
        padding-right: 10px;
      }
      .popup__box {
        max-width: 440px;
        padding: 24px;
      }
      .list-cookies {
        &__item {
          p {
            line-height: 1.4;
            width: calc(100% - 50px);
            span {
              font-size: .813rem;
              line-height: 1.4;
              font-weight: 300;
            }
          }
          .switch-custom {
            align-self: flex-start;
            margin-top: 4px;
            i {
              height: 18px;
              width: 36px;
              &:before {
                height: 14px;
                width: 14px;
              }
            }
          }
        }
      }
      .btn-cookies {
        .button {
          line-height: 30px;
          height: 34px;
          padding: 0 20px;
        }
      }
    }
  }
}
