.sectionmap-center {
	padding: 40px 0 70px; 
	background-color: #FBFBFB;
	overflow: hidden;
	&__text {
		width: 430px;
		margin: 0 auto 52px;
		text-align: center; 
		h6 {
			font-size: .75rem;
			letter-spacing: 5px;
			margin-bottom: 8px;
			color: var(--c-primary-main);
		}
		&--action {
			display: flex;
			gap: 0 24px; 
			justify-content: center; 
			.button {
				display: inline-flex;
				align-items: center;
				justify-content: flex-start;
				padding: 0 16px;
				gap: 0 8px;
				i {
					font-size: 1.5rem; 
				}
			}
		}
	}

	#maps-app{
		margin: 20px 0px;
		height: 453px;		
		.wrap-overflow {
			position: relative;   
			overflow: hidden;   
		}
		.box-drag{			
			width: 100%;
			height: 500px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.accordion{
			position: absolute;
			top:120px;
			right: 56px;
			width: 340px;
			max-height: 531px;
			background-color: var(--c-primary-main); 
			border-radius: 16px;
			padding: 32px;
			z-index: 2;
			display: none; 
			h4 {
				font-size: 1.5rem; 
			}
			h4,a{
				color: var(--c-neutral-white);
				width: 100%;
				display: block;
			}
			a{
				position: relative;
				z-index: 2;
			}
			.overflow-scroll{
				overflow-y: auto;
				overflow-x: hidden;
				max-height: 420px;
				padding-right: 10px;
				&::-webkit-scrollbar-track
				{
						-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
						background-color: #F5F5F5;
				}
	
				&::-webkit-scrollbar
				{
						width: 6px;
						background-color: #F5F5F5;
				}
	
				&::-webkit-scrollbar-thumb
				{
						background-color:var(--c-primary-pressed);
				}
			}
			ul{
				box-sizing: border-box;
			}
			> .overflow-scroll  > ul{
				>li{
					position: relative;
					cursor: pointer;
					&:after{
						content:'';
						position: absolute;
						right: 0;
						top: 5px;
						background: url('/images/material/ic-arr-down-white.svg')no-repeat center;
						width: 19px;
						height: 19px;
						transition: 0.2s linear all;
					}
					.have-subchild{
						position: relative;
						&:after{
							content:'';
							position: absolute;
							right: 0;
							top: 5px;
							background: url('/images/material/ic-arr-down-white.svg')no-repeat center;
							width: 19px;
							height: 19px;
							transition: 0.2s linear all;
						}
					}
	
					&.active,.have-subchild.active {
						&:after {
							transform: rotate(180deg);
						}
					}
	
					.sub-child{
						padding: 10px;
						margin: 0;
					}
	
					.third-sub{
						margin: 0;
						padding: 10px;
						position: relative;
						li{
							position: relative;
							z-index: 2;
						}
						&:before{
							content: '';
							position: absolute;
							left: -42px;
							top: 5px;
							bottom: 5px;
							right: -42px;
							width: auto;
							margin: auto;
							background-color: var(--c-primary-hover);
						}
					}
				}
				// a + ul{
				//   display: none;
				// }
				// a.active + ul{
				//   display: block;
				// }
	
			}
		}
		.box-zoom{
			position: absolute;
			right: 56px;
			top: 52px;
			z-index: 2;
			display: flex;
			display: none; 
			.btn{
				width: 40px;
				height: 40px;
				border-radius: 100%;
				position: relative;
				margin-left: 10px;
				cursor: pointer;
				&.disabled{
					opacity: 0.5;
				}
				&.plus{
					background-color: var(--c-primary-main); 
					&:after{
						content: '';
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						left: 0;
						background: url('/images/material/plus.svg')no-repeat center;
						width: 17px;
						height: 17px;
						margin: auto;
						background-size: contain;
					}
				}
				&.minus{
					background-color: var(--c-primary-main); 
					&:after{
						content: '';
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						left: 0;
						background: url('/images/material/minus.svg')no-repeat center;
						width: 16px;
						height:3px;
						margin: auto;
						background-size: contain;
					}
				}
			}
		}
		figure{
			position: absolute;
			width: 1200px;
			height: auto;
			cursor: move;
			top:0;
			left: 0;
			margin: auto;
			z-index: 1;
			transition: 0.1s ease-in-out transform;
			touch-action: none;
		}
		.desc-box{
			position: absolute;
			top: auto;
			width: 320px;
			background-color: var(--c-neutral-white); 
			z-index: 2;
			box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.16);
			border-radius: 12px;
			.close{
				position: absolute;
				right: 12px;
				top: 12px;
				cursor: pointer;
				z-index: 3; 
			}
			.inner{
				padding: 0;
				display: flex;
				border-radius: 12px;
				position: relative;
				overflow: hidden;
				.img {
					width: 100px;
					border-radius: 12px 0 0 12px;
					position: absolute;
					top: 0;
					left: 0;
					line-height: 0; 
					img {
						border-radius: 12px 0 0 12px;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center; 
					}
				}
				.descrip {
					width: 100%; 
					padding:24px 40px 24px 114px;  
					min-height: 156px; 
					h6 {
						font-size: 1rem;
						margin-bottom: 8px;
					}
					p {
						font-size: .75rem;
						margin: 0;
						line-height: normal; 
					}
					&__action {
						display: flex;
						align-items: center; 
						margin: 16px 0 0 0;
					}
					.button {
						margin: 0; 
						font-size: .875rem;
						height: 20px;
						line-height: 20px;
						padding:0; 
						display: inline-flex;
						gap:0 6px;
						i {
							font-size: 1.25rem;
						}
					}
				}
				
			}
		}
		.listing-pin{
			position: absolute;
			width: 100%;
			height: 100%;
			top:0;
			left: 0;
			&:has(> .pin.active) {
				.pin {
					>i {
						background: url('/images/material/pin-not-active.svg') no-repeat 0 0;
					}
					&.active {
						opacity: 1;
						>i {
							background: url('/images/material/pin.svg') no-repeat 0 0;
						}
					}
				}
			}
			.pin{
				position: absolute;
				width: 42px;
				height: 48px;
				z-index: 3;
				cursor: pointer;
				&.active,
				&:hover{
					.tooltip{
						bottom: 100%; 
						opacity: 1;
						visibility: visible;
					}
				}
        > i{
					width:42px;
					height: 48px;
					border-radius: 100%;
					background: url('/images/material/pin.svg') no-repeat 0 0;
					display: block;
				}
				.tooltip{
					position: absolute;
					bottom: 25px;
					left: 50%;
					transform: translateX(-50%);
					padding: 4px;
					box-sizing: border-box;
					color: var(--c-neutral-white);
					background-color:var(--c-primary-hover); 
					font-size:0.875rem ;
					opacity: 0;
					visibility: hidden;
					transition: .2s all ease-out;
					white-space: nowrap;
					&::after{
						content: '';
						width: 0;
						height: 0;
						border-left: 7px solid transparent;
						border-right: 7px solid transparent;
						border-top: 6px solid var(--c-primary-hover);
						position: absolute;
						top: 100%;
						left: 0;
						right: 0;
						margin: 0 auto;
					}
				}
        .desc-box {
          top: unset;
          left: 50%;
          bottom: 100%;
          margin-bottom: 20px;
          visibility: hidden;
          opacity: 0;
          z-index: -1;
          transition: all .15s ease-out;
          cursor: default; 
          transform: scale(0.9) translateX(-50%);          
        }
        &.active {
          z-index: 5; 
          .desc-box {
            visibility: visible;
            opacity: 1;
            z-index: 1;
            transform: scale(1) translateX(-50%); 
          }
        }
			}
		}
	}

	.accordion-enter-active,
	.accordion-leave-active {
		will-change: height, opacity;
		transition: height .3s ease, opacity .3s ease;
		overflow: hidden;
		position: relative;
	}
	.accordion-enter-from,
	.accordion-leave-to {
		height: 0 !important;
		opacity: 0;
	}

	.pop-enter-active,
	.pop-leave-active {
		transition: 0.15s ease-out all;
	}

	.pop-enter-from,
	.pop-leave-to {
		opacity: 0;
		transform: scale(0.9);
	}

}


/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .sectionmap-center {     
    &__text {
      margin: 0 auto 32px; 
      h3 {
        font-size: 1.875rem; 
      }
      p {
        font-size: .875rem; 
      }
      &--action {
        .button {
          padding: 0 12px; 
        }
      }
    }
    #maps-app {   
      margin: 32px 0px; 
      height: 376px;
      .box-drag {
        height: 376px;
				transform: scale(.83); 
      }
      figure {
        transform: scale(1); 
      }      
      .desc-box {
        width: 303px; 
        .inner {
          .descrip {
            padding: 24px 28px 24px 114px; 
            h6 {
              font-size: .875rem; 
            }            
          }
        }
      }
    }
  }
}


/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .sectionmap-center {
    padding: 40px 0 64px;
    #maps-app {
      height: 276px;
      margin-bottom: 0; 
			margin-top: 0;
			.wrap-overflow {
				overflow: hidden;
			}
      .box-drag {}  
      figure {
        // transform: scale(.6); 
        height: 100%; 
      }
    }
  }
}


/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .sectionmap-center {
    padding-bottom: 48px; 
    &__text {
      width: auto; 
      h3 {
        font-size: 1.5rem; 
      }
      p {
        line-height: normal; 
      }
      &--action {
        gap: 0 16px; 
      }
    }
    #maps-app {
      height: 200px; 
			.box-drag {
				transform: scale(.7);
			}
      figure {
        
      }
      .box-drag {
        height: 276px; 
      }
    }
  }
}