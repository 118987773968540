.sectiontext {
  text-align: left;
  margin-bottom: 72px;
  padding: 100px 0;
  h6 {
    font-weight: 700;
    margin-bottom: 8px;
    color: var(--c-primary-main);
  }
  h2 {
    margin-bottom: 24px;
  }
  h5 {
    font-weight: 400;
    color: var(--c-neutral-gray-4);
    margin-bottom: 24px;
  }
  p {
    margin-bottom: 40px;
  }
  .button,
  button {
    margin-right: 24px;
  }
  &.text-center {
    text-align: center;
    .button,
    button {
      margin: 0;
      margin: 0 12px;
    }
  }
  &.text-right {
    text-align: right;
    .button,
    button {
      margin-left: 24px;
      margin-right: 0;
    }
  }
}
