@import "menu-right";

.wa-header {
  width: 100%;
  // height: 120px;
  background: var(--c-neutral-white);
  position: relative;
  &__fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    padding: 20px 0;
    img {
      display: block;
    }
  }
  &__right {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  &__nav {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 40px 0 0;
    border-right: 1px solid var(--c-neutral-gray-2);
    position: relative;
    li {
      padding: 0 32px;
      margin: 0;
      &:first-of-type {
        padding-left: 0;
      }
      a {
        font-weight: 600;
        color: var(--c-neutral-black);
        display: block;
      }
      &.have-sub {
        position: relative;
        > a {
          position: relative;
          padding-right: 28px;
          &:after {
            content: "\e930";
            font-family: var(--font-webarq-icon);
            font-size: 1.125rem;
            position: absolute;
            top: 0;
            line-height: 1.4;
            bottom: auto;
            color: var(--c-neutral-gray-4);
            right: 0;
          }
        }
        > ul {
          position: absolute;
          padding: 40px 24px 20px;
          min-width: 250px;
          z-index: 8;
          top: 100%;
          margin: 0;
          display: none;
          transition: all ease-out .25s;
          &:before {
            box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            background: var(--c-neutral-white);
            content: '';
            position: absolute;
            top: 20px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
          }
          li {
            padding: 0;
            margin-bottom: 16px;
            &:last-child {
              margin-bottom: 0;
            }
            ul {
              display: none;
              position: relative;
              padding: 10px 20px;
              min-width: 170px;
              transition: none;
              padding-right: 0;
              &:before {
                display: none;
              }
              li {
                margin-bottom: 8px;
                a {
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
      &.have-sub-thumb {
        > a {
          position: relative;
          padding-right: 28px;
          &:after {
            content: "\e930";
            font-family: var(--font-webarq-icon);
            font-size: 1.125rem;
            position: absolute;
            top: 0;
            line-height: 1.4;
            bottom: auto;
            color: var(--c-neutral-gray-4);
            right: 0;
          }
        }
        .drop-sub-menu {
          position: absolute;
          padding: 44px 32px 10px;
          z-index: 8;
          top: 100%;
          margin: 0;
          display: none;
          transition: all ease-out .25s;
          padding-right: 0;
          left: 0;
          &:before {
            box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.05);
            border-radius: 16px;
            background: var(--c-neutral-white);
            content: '';
            position: absolute;
            top: 20px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
          }
          &__wrap {
            display: flex;
          }
          h6 {
            margin-bottom: 20px;
          }
          .col-menu {
            width: 280px;
            padding-right: 60px;
            &.img-thumb {
              padding-right: 0;
              position: relative;
              margin-top: -72px;
              border-radius: 0 16px 16px 0;
              margin-bottom: -10px;
              overflow: hidden;
              img {
                display: block;
                height: 100%;
                object-fit: cover;
              }
            }
            ul {
              li{
                padding: 0;
                margin-bottom: 16px;

                a {
                  font-size: .875rem;
                  font-weight: 700;
                }
                &.have-sub {
                  > ul {
                    position: relative;
                    transition: none;
                    padding: 8px 0 0;
                    padding-left: 12px;
                    li {
                      margin-bottom: 8px;
                      &:last-child {
                        margin-bottom: 0;
                      }
                      a {
                        font-weight: 400;
                      }
                    }
                    &:before {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
        &:nth-last-of-type(1) {
          .drop-sub-menu {
            left: auto;
            right: -10%;
          }
        }
        &:nth-last-of-type(2) {
          .drop-sub-menu {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    @media (hover:hover){
      > .have-sub {
        &:hover {
          > ul {
            display: block;
            transition: all ease-out .25s;
          }
        }
      }
      > .have-sub-thumb {
        &:hover {
          > .drop-sub-menu {
            display: block;
            transition: all ease-out .25s;
          }
        }
      }
    }

  }
  &__lang {
    margin-right: 32px;
  }
  &__search {
    margin-right: 32px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 24px;
      right: -40px;
      top: -2px;
      background-color: #C3C4C5;
      display: none;
    }
    &--button {
      cursor: pointer;
      display: flex;
      align-items: center;
      i {
        font-size: 20px;
        color: var(--c-neutral-gray-4);
      }
    }
    &--content {
      position: absolute;
      box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.05);
      border-radius: 24px;
      background: var(--c-neutral-white);
      overflow: hidden;
      right: -20px;
      top: calc(100% + 24px);
      display: none;
      z-index: 5;
      form {
        position: relative;
        input[type="text"] {
          width: 320px;
          padding-right: 50px;
          border: none;
          margin: 0;
          padding-left: 24px;
          font-size: .875rem;
          background: var(--c-neutral-white);
          position: relative;
        }
        button {
          position: absolute;
          padding: 0 12px;
          color: var(--c-neutral-gray-4);
          top: 0;
          bottom: 0;
          right: 8px;
          background: none;
          border: none;
          cursor: pointer;
        }
      }
    }
    &.act {
      .wa-header__search--content {
        transition: all ease-out .25s;
        display: block;
      }
    }
  }

  &__top {
    background-color: var(--c-primary-hover);
    padding: 14px 0;
    &--wrapper {
      display: flex;
      justify-content: flex-end;
      nav {
        display: flex;
        align-items: center;
        a {
          margin-right: 40px;
          color: var(--c-neutral-white);
          font-size: 0.875rem;
          font-weight: 600;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.inline-lang {
  color: var(--c-neutral-gray-3);
  a {
    color: var(--c-neutral-gray-3);
    &.active {
      color: var(--c-neutral-black);
      font-weight: 700;
    }
  }
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .wa-header {
    &__nav {
      padding-right: 8px;
      margin-right: 24px;
      li {
        padding-left: 16px;
        padding-right: 16px;
        &.have-sub {
          > a {
            padding-right: 24px;
          }
          > ul {
            transition: none;
          }
        }
        &.have-sub-thumb {
          > a {
            padding-right: 24px;
          }
          > .drop-sub-menu {
            transition: none;
          }
          .drop-sub-menu {
            .col-menu {
              width: 240px;
              &.img-thumb {
                margin-top: -69px;
              }
            }
          }
        }
      }
    }
    &__lang {
      margin-right: 30px;
    }
    &__search {
      margin-right: 32px;
      &::after {
        right: -23px;
      }
    }
    &__top {
      padding: 11px 0;
    }
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .wa-header {
    &__nav {
      display: none;
    }
    &__top {
      display: none;
      &--wrapper {
        nav {
          a {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
  .inline-lang {
    a {
      font-size: 0.875rem;
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .wa-header {
    &__logo {
      width: 102px;
    }
    &__search,
    &__lang {
      display: none;
    }
    &__top {
      &--wrapper {
        nav {
          a {
            margin-right: 14px;
          }
        }
      }
    }
    .button {
      padding: 0 16px;
      height: 30px;
      line-height: 26px;
    }
    .wa-menu-right__content > .button {
      height: 42px;
      line-height: 38px;
    }
  }
}
