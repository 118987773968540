

/* header
----------------------------------------------------------------------------------------------*/
@import "../component/dropdown-icon";
@import "../component/search-field";
@import "../section/header/header";

.wa-header {
  background: none;
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  transition: all .2s ease-out;
  &::after {
    background: linear-gradient(180deg, #1B1D22 0%, rgba(27, 29, 34, 0.00) 100%);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    transition: all ease-out .3s;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  &__logo {
    width: 240px;
    position: relative;
    transition: all .2s ease-out;
    img {
      display: block;
      &.logo-2 {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    transition: all ease-out .3s;
    background: var(--c-neutral-white);
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.03);
  }
  &.hide {
    opacity: 0;
  }
  &.white {
    opacity: 1;
    &:before {
      opacity: 1;
      visibility: visible;
    }
    .wa-header__logo {
      width: 180px;
      padding: 12px 0;
      img {
        opacity: 0;
        &.logo-2 {
          opacity: 1;
        }
      }
    }
    .wa-header__nav {
      &:after {
        background: var(--c-neutral-gray-5);
      }
      li {
        > a, > span > a, > span i::before {
          color: var(--c-neutral-gray-5) !important;
        }
        &.have-sub-thumb,
        &.have-sub {
          > a {
            &:after {
              color: var(--c-neutral-gray-5);
            }
          }
         
        }
      }
    }
    .wa-header__search {
      &--button {
        i {
          color: var(--c-neutral-gray-5);
        }
      }
    }
  }
  &.black {
    &:before {
      background: #191B20;
      opacity: 1;
      transition: all ease-in-out .3s;
      visibility: visible;
    }
    .wa-header__logo {
      img {
        opacity: 1;

        &.logo-2 {
          opacity: 0;
        }
      }
    }
    .wa-header__nav {
      &:after {
        background: var(--c-neutral-white);
      }

      li {
        a {
          color: var(--c-neutral-white);
        }

        &.have-sub {
          >a {
            &:after {
              color: var(--c-neutral-white);
            }
          }
          ul {
            li {
              a {
                color: var(--c-neutral-gray-5)
              }
            }
          }
        }
      }
    }

    .wa-header__search {
      &--button {
        i {
          color: var(--c-neutral-white);
        }
      }
    }
  }
  &__nav {
    border: none;
    padding-right: 32px;
    margin-right: 32px;
    gap: 48px;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      height: 20px;
      width: 1px;
      background: var(--c-neutral-white);
      opacity: .6;
      margin: auto;
      left: auto;
      top: 0;
      bottom: 0;
    }
    > li {
      cursor: pointer;
      padding: 0;
      position: relative;
      &::before {
        background: var(--c-primary-hover);
        content: '';
        height: 3px;
        left: 50%;
        position: absolute;
        right: 0;
        top: calc(100% + 5px);
        transform: translateX(-50%);
        transition: .2s all ease;
        width: 0;
      }
      &:hover::before {
        transition: .2s all ease;
        width: 100%;
      }
      > a {
        color: var(--c-neutral-white);
        font-weight: 400;
        position: relative;
      }
      > span > a {
        color: var(--c-neutral-white);
        font-weight: 400;
        position: relative;
        
        &.active {
          color: var(--c-neutral-white) !important;
          font-weight: bold;
          &::before {
            transition: .2s all ease;
            width: 100%;
          }
        }
      }
      &.have-sub-thumb,
      &.have-sub {
        > span {
          align-items: center;
          display: flex;
          gap: 12px;
          > a {
            padding: 0;
          }
          i {
            display: block;
            height: 18px;
            position: relative;
            width: 18px;
            &::before {
              color: var(--c-neutral-white);
              display: block;
              content: "\e930";
              font-family: var(--font-webarq-icon);
              font-size: 1.125rem;
              line-height: 1.4;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
            }
          }
          &:after {
            color: var(--c-neutral-white);
            display: none;
          }
        }
        ul {
          li {
            a {
              color: var(--c-neutral-gray-5);
              font-weight: 400 !important;
            }
          }
        }
      }
      &.have-sub-thumb {
        .drop-sub-menu {
          padding: 52px 0 10px 40px;
          h6 {
            max-width: 260px;
            margin-bottom: 16px;
            color: var(--c-neutral-gray-2);
            font-weight: 500;
            line-height: normal;
          }
          .col-menu {
            width: 320px;
            padding-right: 20px;
            &.img-thumb {
              width: 240px;
              margin-top: -32px;
              margin-bottom: -10px;
            }
            >ul {
              max-height: 305px;
              overflow-y: auto;
              /* width */
              &::-webkit-scrollbar {
                width: 8px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #f1f1f1;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #888;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
              li {
                margin-bottom: 12px;
                line-height: 1.3;
                > a {
                  color: var(--c-neutral-black);
                  &::before {
                    display: none !important;
                  }
                  &.active, &:hover {
                    color: var(--c-primary-main) !important;
                    transition: .2s all ease;
                    font-weight: bold !important;
                  }
                }
                >ul{
                  padding-left: 12px;
                  padding-top: 8px;
                  li{
                    margin-bottom: 4px;
                    a{
                      opacity: .6;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &__search {
    margin-right: 0;
    &--button {
      i {
        color: var(--c-neutral-white);
      }
    }
  }
}

.wa-menu-right {
  min-height: calc(var(--vh, 1vh)* 100);
}



/* footer
----------------------------------------------------------------------------------------------*/
@import "../section/footer/footer-complex";

.wa-footer-complex {
  background: #1B1D22 url('/images/material/bg-footer.svg') no-repeat bottom center;
  background-size: 100%;
  position: relative;
  z-index: 8;
  &__top {
    padding: 100px 0 72px;
    &--nav {
      grid-template-columns: 3fr 1fr;
      column-gap: 40px;
      &__left {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 40px;
        &--box {
          margin-bottom:16px;
        }
      }
      a {
        line-height:1.2;
        font-weight: 400;
        color: var(--c-neutral-gray-1);

      }
      nav {
        a {
          margin-bottom: 8px;
        }
      }
      h6 {
        font-weight: 700;
        line-height: 160%;
        margin-bottom: 8px;
        margin-top: 16px;
        color: var(--c-neutral-white);
        &:nth-of-type(1) {
          margin-top: 0;
        }
        a {
          font-weight: 700;
          display: block;
          color: var(--c-neutral-white);
        }
      }
      .sosmed {
        display: flex;
        margin: 0;
        margin-top: 16px;
        a {
          margin: 0;
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  &__bottom {
    background: transparent;
    color: rgba(255,255,255,.7);
    a {
      color: rgba(255, 255, 255, .7);
    }
  }
  &__link {
    display: flex;
    a {
      padding-left: 32px;
      margin-left: 12px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        left: 12px;
        top:0;
        bottom: 0;
        margin: auto;
        background: rgba(255, 255, 255, .7);

      }
      &:first-child {
        margin-left: 0;
        padding-left: 0;
        &:before {
          display: none;
        }
      }
    }
  }
}

/* responsive desktop < 1400
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1383px) {
  .wa-header {
    &__nav {
      li {
        &.have-sub-thumb {
          &:nth-child(3) {
            .drop-sub-menu {
              margin-left: -100px;
            }
          }
          &:nth-child(4) {
            .drop-sub-menu {
              margin-left: -170px;
            }
          }
        }
      }
    }
  }

  .wa-footer-complex {
    &__top {
      &--nav {
        &__box {
          font-size: .875rem;
        }
      }
    }
  }

}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .wa-header {
    &__logo {
      width: 191px;
    }
    &__nav {
      gap: 24px;
      li {
        a {
          font-size: 0.938rem;
        }
        &.have-sub-thumb {
          &:nth-last-of-type(3) {
            .drop-sub-menu {
              left: 50%;
              transform: translateX(-50%);
              margin-left: -50px;
            }
          }
        }
      }
    }
  }
  .wa-footer-complex {
    &__top {
      padding: 80px 0 72px;
      &--nav {
        h6 {
          margin-bottom: 16px;
          a {
            font-size: 0.938rem;
          }
        }
      }
    }
    &__copyright {
      font-size: 0.75rem;
    }
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .wa-header {
    &__search {
      &--button {
        i {
          font-size: 32px;
        }
      }
    }
    &.white {
      .burger-button {
        &::before {
          background: var(--c-neutral-black);
          opacity: 0.3;
        }
        &__lines {
          span {
            background-color: var(--c-primary-main);
          }
        }
      }
    }
  }
  .burger-button {
    margin-left: 56px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -30px;
      width: 1px;
      height: 28px;
      background: var(--c-neutral-white);
    }
    &__lines {
      height: 28px;
      span {
        background-color: var(--c-neutral-white);
      }
    }
  }
  .wa-menu-right {
    max-width: 374px;
    background: var(--c-neutral-white);
    padding: 130px 0 24px 24px;
    right: -374px;
    transition: 0.3s all ease-out;
    &__logo {
      position: absolute;
      top: 24px;
      left: 24px;
    }
    &__close {
      top: 32px;
      right: 24px;
      &::before,
      &::after {
        background: var(--c-neutral-black);
      }
    }
    &__overflow {
      max-height: calc(100svh - 250px);
      padding-right: 22px;
    }
    &__content {
      > ul {
        border-bottom: unset;
        margin-bottom: 0;
        padding-bottom: 12px;
        > li {
          margin-bottom: 24px;
          position: relative;
          > a {
            font-size: 1.125rem;
            font-weight: 400;
            text-align: left;
            color: var(--c-neutral-gray-5);
            padding-right: 42px;
            &.active {
              color: var(--c-primary-main);
              font-weight: 500;
            }
          }
          &.have-sub {
            .arrow {
              position: absolute;
              top: 0px;
              right: 0;
              width: 32px;
              height: 24px;
              background: url('/images/material/chevron-down-black.svg') no-repeat right 0;
              transition: all .3s ease-out;
              content: "";
              z-index: 3;
            }
            > a {
              &::before {
                top: -2px;
                width: 32px;
                height: 24px;
                background: url('/images/material/chevron-down-black.svg') no-repeat;
                background-position: center;
                transition: all .3s ease-out;
                display: none;
              }
              &::after {
                content: unset;
              }
            }
            &.active {
              .arrow {
                background: url('/images/material/chevron-down-main.svg') no-repeat right 0;
                transform: rotate(360deg);
              }
              > a {
                color: var(--c-primary-main);
                font-weight: 700;
                &::before {
                  background: url('/images/material/chevron-down-main.svg') no-repeat;
                  transform: rotate(360deg);
                }
              }
            }
            ul {
              padding: 12px 0 0;
              li {
                text-align: left;
                a {
                  font-size: 1rem;
                  color: var(--c-neutral-gray-5);
                  &.active {
                    color: var(--c-primary-main);
                    font-weight: 500;
                  }
                }
                >ul{
                  display: block !important;
                  padding-left: 12px;
                  li{
                    margin-bottom: 8px;
                    line-height: 1;

                  }
                }
                &.have-sub {
                  > a {
                    &::after {
                      right: 7px;
                    }
                  }
                  ul {
                    margin-left: 12px;
                  }
                }
              }
            }
          }
        }
      }
      .search-field {
        margin: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 0 48px;
        background: var(--c-neutral-white);
        z-index: 3;
        margin: 0 24px;
        width: calc(100% - 48px);
        max-width: none;
        &::before {
          color: var(--c-neutral-gray-5);
          right: 20px;
          top: 3px;
          transform: translateY(0);
        }
        input {
          border: 1px solid rgba(0, 0, 0, 0.10);
          background: none;
          color: var(--c-neutral-gray-2);
          &::placeholder {
            color: var(--c-neutral-gray-2);
          }
        }
      }
    }
  }
  .wa-footer-complex {
    &__top {
      &--nav {
        gap: 0 40px;
        grid-template-columns: 4fr;
        h6 {
          font-size: 0.875rem;
        }
        &__box {
          grid-row: span 2;
          &:nth-child(3) {
            grid-row: span 1;
          }
        }
      }
    }
  }
}


/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .wa-header {
    &__wrapper {
      height: 72px;
      align-items: center;
    }
    &__logo {
      width: 138px;
      padding: 0;
    }
    &.white {
      .wa-header__logo {
        width: 138px;
        padding: 0;
      }
    }
  }
  .burger-button {
    margin-left: 0;
    &::before {
      content: unset;
    }
    &__lines {
      width: 32px;
      height: 20px;
      span {
        height: 2px;
      }
    }
  }
  .wa-menu-right {
    width: 100%;
    max-width: 100%;
    &__content {
      > ul {
        > li {
          a {
            width: 100%;
          }
          &.have-sub {
            > a {
              &::before {
                top: 2px;
              }
            }
          }
        }
      }
    }
  }
  .wa-footer-complex {
    &__top {
      padding: 40px 0;
      &--nav {
        grid-template-columns: repeat(1, 1fr);
        &__left {
          grid-template-columns: repeat(1, 1fr);
        }
        &__right {
          border-bottom: 1px solid hsla(0, 0%, 100%, .11);
          border-top: 1px solid hsla(0, 0%, 100%, .184);
          padding: 24px 0;
        }
        &__box {
          &:first-child {
            order: 1;
          }
          &:nth-child(2) {
            order: 3;
          }
          &:nth-child(3) {
            order: 2;
          }
          &:nth-child(4) {
            order: 4;
            margin-top: 24px;
            padding: 24px 0;
            border-top: 1px solid #ffffff2f;
            border-bottom: 1px solid #ffffff1c;
          }
        }
        h6 {
          margin-bottom: 8px;
          a {
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}
