.search-field {
  width: 100%;
  max-width: 560px;
  position: relative;
  &::before {
    content: "\e9b4";
    font-family: var(--font-webarq-icon);
    color: var(--c-neutral-gray-4);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 19px;
    font-size: 24px;
  }
  input {
    width: 100%;
    height: 50px;
    border-radius: 64px;
    font-size: 1rem;
    padding: 0 56px 0 24px;
    margin: 0;
  }
}

@media all and (max-width: 767px) {
  .search-field {
    input {
      height: 42px;
    }
  }
}