.section-service-focus {
  position: relative;
  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    z-index: -1;
    transition: all .3s ease-in-out;
    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: "";
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 8.25%, rgba(0, 0, 0, 0) 100%);
      transform: rotate(-180deg);
      z-index: 3;
    }
    figure {
      position: absolute;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      transition: all .4s ease-in-out;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      &.bg-primary {
        top: 0;
        z-index: 2;
      }
      &.bg-hover {
        top: 0;
        z-index: -1;
        overflow: hidden;
        img {
          transition: all .4s ease-in-out;
        }
      }
    }
    &.first {
      opacity: 1;
      z-index: 1;
    }
    &.active {
      z-index: 3;
      display: block;
      opacity: 1;
      figure {
        &.bg-primary {
          top: 0%;
          z-index: -1;
          opacity: 0;
        }
        &.bg-hover {
          top: 0;
          z-index: 2;
          transform: scale(1);
          border-radius: 0;
          transition: all .4s ease-in-out;
          img {
            display: block;
            transition: all .4s ease-in-out;
            transform: scale(1.05);
          }
        }
      }
    }
  }
  &__list {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 4;
    &--item {
      flex: 0 0 33.333%;
      max-width: 33.333%;
      padding:130px 64px;
      height: 460px;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      color: var(--c-neutral-white);
      transition: all .3s ease-out;
      &:last-child {
        border-right: 0;
      }
      figcaption {
        position: relative;
        padding-bottom: 95px;
        transition: all .3s ease-out;
        z-index: 3;
      }
      h3 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: normal;
        max-width: 352px;
        transition: all .3s ease-out;
        margin-bottom: 0;
        overflow: hidden;
      }
      p {
        font-size: 1rem;
        max-width: 352px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        position: absolute;
        transition: all .3s ease-out;
        opacity: 0;
        left: 0;
        bottom: -30px;
        overflow: hidden;
      }
      button,
      .button {
        cursor: inherit;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        border-color: var(--c-neutral-white);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: all .3s ease-out;
        i {
          color: var(--c-neutral-white);
          font-size: 20px;
        }
        &:hover {
          background: none;
        }
      }
      &:hover {
        color: var(--c-neutral-white);
        padding-top:93px;
        padding-bottom:93px;
        p {
          opacity: 1;
          bottom: 0;
        }
        button,
        .button {
          background: var(--c-primary-main);
          border-color: var(--c-primary-main);
          bottom: -70px;
        }
      }
    }
  }
}


/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .section-service-focus {
    &__list {
      &--item {
        padding-left: 40px;
        padding-right: 40px;
        h3 {
          max-width: 260px;
          font-size: 1.875rem;
        }
      }
    }
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .section-service-focus {
    &__list {
      flex-direction: column;
      align-items: normal;
      &--item {
        flex: 0 0 100%;
        max-width: 100%;
        height: auto;
        padding-top: 74px;
        padding-bottom: 74px;
        border-right: 0;
        border-bottom: 1px solid rgba(255,255,255, .2);
        &:last-child {
          border-bottom: 0;
        }
        h3 {
          max-width: 100%;
        }
        p {
          max-width: 100%;
        }
        &:hover {
          padding-top:74px;
          padding-bottom:148px;
        }
      }
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .section-service-focus {
    &__list {
      &--item {
        padding: 40px;
        figcaption {
          padding-bottom: 85px;
        }
        h3 {
          font-size: 1.5rem;
        }
        p {
          font-size: .875rem;
        }
        &:hover {
          padding-top: 40px;
          padding-bottom: 100px;
          button,
          .button {
            bottom: -50px;
          }
        }
      }
    }
  }
}
