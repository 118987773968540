.burger-button {
  text-align: center;
  margin-left: 50px;
  cursor: pointer;
  display: none;
  &__lines {
    width: 44px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span {
      display: block;
      width: 100%;
      height: 4px;
      background-color: var(--c-primary-main);
      border-radius: 2px;
    }
  }
  &__text {
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--c-primary-pressed);
    display: none;
  }
  &.burger-desktop {
    display: block;
  }
}

.wa-menu-right {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 420px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: var(--c-primary-main);
  color: var(--c-neutral-white);
  padding: 100px 70px 88px 50px;
  text-align: right;
  z-index: 11;
  display: none;
  a {
    color: var(--c-neutral-white);
  }
  &__close {
    position: absolute;
    top: 40px;
    right: 70px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--c-neutral-white);
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
    @media (hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__overflow {
    overflow-y: auto;
    max-height: calc(100vh - 120px);
    padding-right: 10px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--c-primary-hover);
      border-radius: 5px;
    }
    @media (hover: hover) {
      &::-webkit-scrollbar-thumb:hover {
        background: var(--c-primary-pressed);
      }
    }
  }
  &__content {
    > ul {
      border-bottom: 1px solid var(--c-primary-hover);
      padding-bottom: 24px;
      margin-bottom: 37px;
      > li {
        margin-bottom: 12px;
        > a {
          font-size: 1.5rem;
          font-weight: 700;
          position: relative;
          padding-right: 30px;
          display: block;
        }
        ul {
          display: none;
          padding: 20px 0 0;
          li {
            font-size: 1.25rem;
            margin-bottom: 8px;
          }
        }
        &.have-sub {
          > a {
            &::before,
            &::after {
              content: "";
              position: absolute;
              top: 17px;
              right: 0;
              width: 12px;
              height: 2px;
              background-color: var(--c-neutral-white);
              border-radius: 2px;
            }
            &::after {
              transform: rotate(90deg);
            }
          }
          ul {
            li {
              a {
                padding-right: 30px;
              }
              &.have-sub {
                > a  {
                  position: relative;
                  &:after {
                    content: "\e930";
                    font-family: 'Webarq-Icons' !important;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    left: auto;
                    right: 0;
                    font-size: 1.125rem;
                    transition: all ease-out .25s;
                    line-height: 1.4;
                  }
                }
                &.active {
                  > a {
                    &:after {
                      transition: all ease-out .25s;
                      transform: rotate(180deg);
                    }
                  }
                }
                ul {
                  display: none;
                  margin-bottom: 20px;
                  padding-top: 12px;
                  li {
                    a {
                      font-size: 16px;
                      padding-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
        &.active {
          > a {
            &::after {
              transform: rotate(0deg);
            }
          }
        }
      }
    }
    >.button {
      width: 100%;
      margin-bottom: 16px;
      height: 50px;
      line-height: 48px;
    }
    .menu-top-mobile {
      margin-bottom: 24px;
      a {
        margin-left: 18px;
        margin-bottom: 12px;
        opacity: .8;
        display: inline-flex;
      }
    }
    .search-field {
      margin-bottom: 28px;
      &::before {
        color: var(--c-neutral-white);
      }
      input {
        background: var(--c-primary-hover);
        color: var(--c-neutral-white);
        border: none;
        &::-webkit-input-placeholder {
          color: var(--c-neutral-white);
        }
        &:-ms-input-placeholder {
          color: var(--c-neutral-white);
        }
        &::placeholder {
          color: var(--c-neutral-white);
        }
      }
    }

    .lang-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        display: flex;
        align-items: center;
        margin-right: 24px;
        img {
          margin-right: 8px;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .burger-button {
    margin-left: 38px;
  }
  .wa-menu-right {
    padding: 80px 50px;
    &__close {
      top: 30px;
      right: 50px;
    }

  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .burger-button {
    display: block;
    &__text {
      display: block;
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .burger-button {
    margin-left: 20px;
    &__text {
        display: none;
      }
  }
  .wa-menu-right {
    padding: 80px 30px;
    &__close {
      right: 30px;
    }
    &__content {
      > ul {
        margin-bottom: 28px;
        > li {
          text-align: center;
          > a {
            font-size: 1.375rem;
            display: inline-flex;
          }
          ul {
            li {
              font-size: 1.125rem;
            }
          }
        }
      }
      .menu-top-mobile {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        a {
          display: block;
          margin: 0 10px 12px;
        }
      }
      > .button {
        height: 42px;
        line-height: 38px;
      }
    }
  }
}
