@import "../component/key-facts";
.sectioncontent-facts {
  position: relative;
  overflow: hidden;
	padding: 64px 120px; 
	&__column {				
		&__text {			
			padding-right: 52px; 
			h6 {
				font-size: .75rem;
				letter-spacing: 5px;
				margin-bottom: 10px; 
				color: var(--c-primary-main);
			}
			h5 {
				color: var(--c-neutral-gray-4);
				font-weight: 400; 
			}
			p {
				line-height: 1.4; 
			}
			&--action {
				display: flex;
				>*+* {
						margin-left: 20px;
					}
				.button {
					display: inline-flex;
					align-items: center;
					justify-content: flex-start;					
					padding: 0 16px;
					i {
						font-size: 1.5rem;
					}
					&.button-white {
						color: var(--c-neutral-white); 
					}
				}
			}
		}
		&__image {
			position: relative;
			height: 100%;
			> img {
				width: 100%; 
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
			.play {
				position: absolute;
				cursor: pointer;
				width: 100px;
				height: 100px;
				margin: auto;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				display: block;
				img {
					display: block;
					margin: auto;
					width: 100%;
				}
			}
		}
	}

	&.facts-bg {
		.facts-bg__image {
			position: absolute;
			width: 100%;
			height: 100%; 
			top: 0;
			left: 0; 
			img {
				width: 100%; 
				height: 100%;
				object-fit: cover;
				object-position: center; 
			}
			&::before {
				position: absolute;
				width: 100%;
				height: 100%;
				content: "";
				left: 0;
				top: 0;
				opacity: 0.5;
				background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
			}
			&::after {
				position: absolute;
				width: 100%;
				height: 100%;
				content: "";
				left: 0;
				top: 0;
				opacity: 0.5;
				background: #000; 
			}
		}
		.sectioncontent-facts__column {
			z-index: 5;
			position: relative; 
			&__text {
				color: var(--c-neutral-white);
				h5 {
					color: var(--c-neutral-white);
				} 
			}			
		}
		.key-facts {
			color: var(--c-neutral-white); 
			p {
				color: var(--c-neutral-gray-2);  
			}
		}
	}

  
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
	.sectioncontent-facts {
		padding: 64px 40px; 
		&__column {
			&__text {
				padding-right: 42px;
				h3 {
					font-size: 1.875rem;
					padding-right: 20px; 
				}
				p {
					font-size: .857rem; 
				}
			}
		}
	}
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
	.sectioncontent-facts {
		padding:50px 40px;  
		&__column {
			.row {
				flex-direction: column;
				.column {
					flex: 0 0 100%;
					max-width: 100%; 
				}
			}
			&__text {
				padding: 0; 
				margin-bottom: 30px;  
			}
			&__image {
				height: 300px; 
			}
		}
	}
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
	.sectioncontent-facts {
		padding: 50px 16px; 
		&__column {
			&__text {
				margin-bottom: 43px; 
				h3 {
					font-size: 1.5rem; 
				}
			}
			&__image {
				height: auto;
				min-height: 230px; 
			}
		}
		&.facts-bg {
			.sectioncontent-facts {
				&__column {
					&__text {
						margin-bottom: 0; 
					}
				}
			}
		}
	}
}
