.wa-footer-complex {
  background-color: var(--c-neutral-black);
  color: var(--c-neutral-white);
  a {
    color: var(--c-neutral-white);
    @media (hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__logo {
    margin-bottom: 12px;
    display: block;
  }
  &__top {
    padding: 54px 0 74px;
    &--wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 34px;
      border-bottom: 1px solid var(--c-neutral-gray-5);
      margin-bottom: 60px;
    }
    &--left {
      width: 100%;
      max-width: 453px;
      address {
        display: flex;
        i {
          margin-right: 8px;
          font-size: 20px;
        }
        p {
          font-style: normal;
          margin: 0;
        }
      }
    }
    &--right {
      a {
        margin-right: 72px;
        i {
          margin-right: 8px;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    &--nav {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      column-gap: 32px;
      h6 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 24px;
      }
      nav {
        a {
          margin-bottom: 12px;
          display: block;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &__bottom {
    background-color: var(--c-neutral-white);
    color: var(--c-neutral-gray-4);
    font-size: 0.875rem;
    padding: 28px 0;
    &--wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    a {
      color: var(--c-neutral-gray-4);
    }
  }
  &__nav-right {
    display: flex;
    align-items: center;
    nav {
      display: flex;
      align-items: center;
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
      a {
        margin-right: 34px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  &__socmed {
    display: flex;
    align-items: center;
    a {
      margin-right: 8px;
      &:last-of-type {
        margin-right: 0;
      }
      @media (hover: hover) {
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .wa-footer-complex {
    &__top {
      &--right,
      &--nav {
        font-size: 0.875rem;
      }
    }
    &__bottom {
      padding: 24px 0;
    }
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .wa-footer-complex {
    &__top {
      &--left,
      &--right {
        width: 45%;
      }
      &--right {
        a {
          display: block;
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
      &--nav {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 40px;
      }
    }
    &__bottom {
      font-size: 0.75rem;
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .wa-footer-complex {
    &__top {
      &--wrapper {
        flex-direction: column;
      }
      &--left,
      &--right {
        width: 100%;
      }
      &--left {
        font-size: 0.875rem;
        margin-bottom: 24px;
      }
      &--nav {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &__bottom {
      text-align: center;
      &--wrapper {
        flex-direction: column;
      }
    }
    &__copyright {
      margin-bottom: 10px;
    }
  }
}
