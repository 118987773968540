@import "../component/popup";
.sectioncontent-aside {
  width: 100%;
  position: relative;
  &__background {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 70%;
    height: 100%;
    background-color: var(--c-primary-surface);   
  }
  &__content {
    .wrapper {
      position: relative;
      display: flex;
      padding: 100px 0;
      .row {
        align-items: center;        
        .column-50 {
          figure {
            img {
              float: left;
            }
          }
        }
        figure {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: var(--c-neutral-black);
            opacity: 0.5;
            z-index: 3;
          }
          img {
            position: relative;
          }
        }
        h5 {
          font-weight: 400;
        }
        h6 {
          color: var(--c-primary-main);
        }
        button{
          padding: 0 24px;
          &.button-icon.button-outline {
            border-color: var(--c-secondary-main);
            color: var(--c-secondary-main);
            margin-left: 20px;
          }
        }
      }
    }
  }
  &.reverse {
    .sectioncontent-aside {
      &__background {
        left: 0;
        right: unset;
      }
      &__content {
        .row {
          flex-direction: row-reverse;
          .column-50 {
            figure {
              img {
                float: right;
              }
            }
          }
        }
      }
    }    
  }
  &.with-video {
    figure {
      position: relative;
      .play {
        font-size: 6rem;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        color: var(--c-neutral-white);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 4;
        i {
          line-height: 1;
          transition: all ease-out .25s;
        }
        span {
          display: block;
          font-size: .875rem;
          line-height: 1;
          margin-top: 8px;
          transition: all ease-out .25s;
        }
        &:hover {
          i {
            transition: all ease-out .25s;
            transform: scale(.93);
          }
          span {
            transition: all ease-out .25s;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .sectioncontent-aside {
    &__content {
      .row {
        .column {
          &.column-40 {
            flex: 0 0 45%;
            max-width: 45%;
          }
          &.column-offset-10 {
            margin-left: 5%;
          }
        }
      }
    }
    .wrapper {
      margin: 0;
      padding: 80px 40px;
    }
  }
}

@media (max-width: 1023px) {
  .sectioncontent-aside {
    height: auto;
    padding: 140px 0;
    &__content {
      .wrapper {
        height: auto;
        .row {
          .column {
            &.column-40 {
              flex: 0 0 45%;
              max-width: 45%;
            }
            &.column-offset-10 {
              margin-left: 5%;
            }
          }
          button{
            padding: 0 20px;
            &.button-icon.button-outline {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .sectioncontent-aside {
    padding: 70px 0;
    &__background {
      width: 100%;
      height: 80%;
      top: unset;
      bottom: 0;     
    }
    &__content {
      .wrapper {
        padding: 0 16px;
        .row {
          flex-direction: column;
          margin-left: 0;         
          .column {
            &.column-40,
            &.column-50 {
              flex: unset;
              max-width: 100%;
              padding: 0;
            }
            &.column-offset-10 {
              margin-left: 0;
            }
          }
          figure {
            margin-bottom: 50px;
            img {
              width: 100%;
            }
          }
          figcaption {
            margin-bottom: 50px;
            text-align: center;
          }
        }
      }
    }
    &.reverse {
      .sectioncontent-aside {
        &__background {
          top: 0;
          bottom: unset;
        }
        &__content {
          .row {
            flex-direction: column-reverse;
          }
        }
      }
    }
  }
}
