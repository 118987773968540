.key-facts {
	margin-top: 70px; 
	position: relative;
	z-index: 3; 
	.row {
		.column {
			h3 {
				margin-bottom: 8px;
			}
			p {
				margin: 0 12px 0 0;  
				line-height: 1.5; 
				color: var(--c-neutral-gray-4); 
				b {
					color: var(--c-primary-main); 
				}
			}
		}
	}
}

/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
	.key-facts {
		.row {
			.column {
				h3 {
					font-size: 1.875rem; 
				}
				p {
					font-size: .875rem; 
				}
			}
		}
	}
}


/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
	.key-facts {
		margin-top: 50px; 
		.row {
			.column {
				flex: 0 0 50%;
				max-width: 50%; 
				margin-bottom: 20px;
				h3 {
					margin-right: 42px; 
				} 
				p {
					margin: 0 42px 0 0;
				}
			}
		}
	}
}


/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
	.key-facts {
		margin-top: 43px;
		.row {
			.column {
				flex: 0 0 100%;
				max-width: 100%; 
				h3 {
					margin-right: 0; 
					font-size: 1.5rem; 
				}
				p {
					margin: 0;
				}
			}
		}
	}
}