/*=============================================================================================
		Company    : PT Web Architect Technology - webarq.com
		Document   : Stylesheet
		Author     : Your Name
==============================================================================================*/
/* helper
----------------------------------------------------------------------------------------------*/
// @import "../milligram/Color";
/* general
----------------------------------------------------------------------------------------------*/
@import "swiper/css/bundle";
@import "../component/layout";
@import "../milligram/Mixins";

/* home styling
----------------------------------------------------------------------------------------------*/
.animating-scroll {
  position: relative;
  z-index: 3;
  background: var(--c-neutral-white);

  .trigger-anim {
    height: 30%;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &.sticky {
    z-index: 1;
    top: 0;
    position: sticky;
  }
}

.splash-white {
  position: relative;
  z-index: 3;
  height: 60svh;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.39) 35.45%, rgba(255, 255, 255, 0.73) 63.97%, rgba(255, 255, 255, 0.91) 84.63%, #FFF 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-transition: background-color .9s cubic-bezier(0.165, 0.84, 0.44, 1) 150ms;
  transition: background-color .9s cubic-bezier(0.165, 0.84, 0.44, 1) 150ms;
}
.splash-black {
  position: relative;
  z-index: 5;
  height: 90svh;
  background: linear-gradient(180deg, rgba(25, 27, 32, 0.00) 0%, rgba(25, 27, 32, 0.32) 30.66%, rgba(25, 27, 32, 0.71) 55.34%, rgba(25, 27, 32, 0.91) 73.2%, #191B20 86.5%);
 }


@import "../section/hero-banner/hero-banner";

.herobanner-full{
  &__list {
    &__image {
      &:before {
        content: '';
        height: 20%;
        background: linear-gradient(180deg, #1B1D22 0%, rgba(27, 29, 34, 0.00) 100%);
        bottom: auto;
      }
      &:after {
        height: 50%;
        top: auto;
        bottom: 0;
        background: linear-gradient(180deg, #1B1D22 0%, rgba(27, 29, 34, 0.00) 100%);
        opacity: 1;
        transform: rotate(180deg);
      }
      img,
      video {
        height: 100vh;
        height: calc(var(--vh, 1vh)* 100);
      }
    }
    .wrapper {
      top: auto;
      transform: translateX(0);
      bottom: 112px;
      left: 0;
      margin: auto;
      right: 0;
      height: auto;
      figcaption {
        .title-large {
          margin: 0;
        }
      }
    }
    &__text {
      max-width: 870px;
      h2 {
        line-height: 120%;
        text-transform: uppercase;
        opacity: 0;
        transition: all ease-in-out .35s;
        top: 30px;
        position: relative;
      }
    }
    &.video {
      .herobanner-full__list__image {

        &:before,
        &:after {
          display: block;
        }
      }
    }
    &.swiper-slide-active {
      .herobanner-full__list__text h2 {
        top: 0;
        opacity: 1;
        transition: all ease-in-out .35s;
        top: 0px;
      }
    }
  }
  .swiper-pagination-bullets {
    &.swiper-pagination-horizontal {
      bottom: 56px;
      .swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
  .scroll-down {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: auto;
    left: auto;
    right: 120px;
    bottom: 32px;
    z-index: 7;
    i {
      font-size: 1.5rem;
      color: #0B0C0E;
      position: relative;
      top: -4px;
      animation: topBottom 1.25s infinite;
    }
  }
  &.after-animate {
    .herobanner-full__list {
      .wrapper figcaption .title-large {
        top: 0;
        opacity: 1;
        transition: all ease-in-out .35s;
        top: 0px;
      }
    }
  }
}

@keyframes topBottom {
  0% {
    top: -2px;
  }
  25% {
    top: 0px;
  }
  50% {
    top: 2px;
  }
  75% {
    top: 0px;
  }
  100% {
    top: -2px;
  }
}

@import "../section/section-content/section-content-facts";

.sectioncontent-facts {
  padding: 100px 0 80px;
  &.facts-bg {
    .facts-bg__image{
      &:before,
      &:after {
        display: none;
      }
    }
    .sectioncontent-facts__column {
      padding-bottom: 60px;
      &__text {
        color: var(--c-neutral-gray-5);
        h3, h1 {
          font-size: 2.5rem;
          font-weight: 400;
          line-height: 130%;
          margin-bottom: 24px;
        }
        p {
          color: var(--c-neutral-gray-3);
          margin-bottom: 40px;
          line-height: 180%;
        }
      }
    }
    .key-facts {
      .row {
        align-items: center;
      }
      .ico-text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        > span {
          width: 48px;
          img {
            display: block;
          }
        }
        &__desc {
          width: calc(100% - 48px);
          padding-left: 24px;
          h4 {
            margin-bottom: 4px;
            color: var(--c-neutral-gray-5);
            line-height: 130%;
          }
          p {
            color: var(--c-neutral-gray-3);
            line-height: 160%;
            margin: 0
          }
        }
      }
    }
  }
  &.animating-scroll {
    &.facts-bg {
      .sectioncontent-facts__column {
        &__text {
          @include anim-indicator(from, top, 30px, 0, 2, "h6", "h1","p","> div");
        }
      }
      .facts-bg__image {
        img {
          opacity: 0;
          transform: scale(0.95);
        }
      }
      .key-facts {
        .row {
          .column {
            @include anim-listing(from, top, 30px, 0, 1, 6);
          }
        }
      }
    }
    &.after-animate {
      &.facts-bg {
        .sectioncontent-facts__column {
          &__text {
            @include anim-indicator(to, top, 0px, .3, .3, "h6", "h1", "p", "> div");
          }
        }
        .facts-bg__image {
          img {
            opacity: 1;
            transform: scale(1);
            transition: all ease-in-out .4s;
            transition-delay: .2s;
          }
        }
        .key-facts {
          .row {
            .column {
              @include anim-listing(to, top, 0px, .2,  1.5, 6);
            }
          }
        }
      }
    }
  }
}

.bg-section {
  position: relative;
  background: var(--c-neutral-white) url('/images/material/bg-section.svg') no-repeat top center;
  background-size: cover;
  z-index: 3;
  .animating-scroll {
    background: transparent
  }
}


@import "../section/section-service/section-service-focus";

.section-service-focus {
  padding: 12px 0;
  .wrapper {
    border-radius: 16px;
    overflow: hidden;
  }
  &__list {
    &--item {
      height: 620px;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 48px 72px;
      display: flex;
      align-items: flex-end;
      h3 {
        margin-bottom: 12px;
      }
      h3,p {
        max-width: 100%;
      }
      p {
        position: relative;
        height: 0;
        bottom: 0;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        margin: 0;
      }
      figcaption {
        padding-bottom: 72px;
        width: 100%;
      }
      &:hover {
        padding-bottom: 48px;
        h3 {
          color: var(--c-secondary-main);
        }
        p {
          height: 48px;
          visibility: visible;
          opacity: 1;
        }
        .button {
          bottom: 0;
          background: var(--c-secondary-main);
          border-color: var(--c-secondary-main);
          i {
            color: var(--c-neutral-black)
          }
        }
      }
    }
  }
  &.animating-scroll {
    @include anim-indicator(from, top, 30px, 0, 2, ".wrapper", ".maps-custom");
    &.after-animate {
      @include anim-indicator(to, top, 0px, .3, .2, ".wrapper", ".maps-custom");
    }
  }
}

@import "../section/section-map/section-map-center";
.sectionmap-center {
  background: none;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  #maps-app {
    height: 600px;
    figure {
      background: var(--c-neutral-white);
      border-radius:16px;
      img {
        display: block;
        width: 100%;
      }
    }
    .listing-pin {
      .pin {
        width: 23px;
        height: 12px;
        transition: all .25s ease-out;
        > i {
          width: 23px;
          height: 12px;
          background: url('/images/material/pin.png') no-repeat 0 0;
        }
        .pintext {
          position: absolute;
          width: 235px;
          min-height: 52px;
          bottom: 100%;
          right: 100%;
          text-align: right;
          margin-right: -13px;
          margin-bottom: -5px;
          padding-right: 10px;
          &::before {
            position: absolute;
            width: 2px;
            height: 100%;
            right: 0;
            bottom: 0;
            content: "";
            background: var(--c-primary-main);
          }
          h6 {
            font-size: 1rem;
            font-weight: 700;
            margin: 0 0 3px;
            line-height: 1;
            color: var(--c-neutral-gray-4);
          }
          p {
            font-weight: 400;
            margin: 0;
            line-height: 1;
            font-size: .875rem;
            color: var(--c-neutral-gray-4);
          }
        }
        &.height-medium {
          .pintext {
            min-height: 72px;
          }
        }
        &.height-large {
          .pintext {
            min-height: 116px;
          }
        }
        &.right-text {
          .pintext {
            left: 100%;
            right: unset;
            text-align: left;
            padding-left: 10px;
            padding-right: 0;
            margin-left: -13px;
            &::before {
              left: 0;
            }
          }
        }
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    .box-drag {
      height: 600px;
    }
  }
  .custom-text {
    position: absolute;
    width: 1200px;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
  }
  &__text {
    max-width: 832px;
    width: 832px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 24px;
    margin-bottom: 0;
    position: relative;
    padding: 20px;
    &::after {
      content: "";
      height: 100%;
      position: absolute;
      transform: rotate(-180deg);
      width: 100%;
      z-index: -1;
      background: linear-gradient(180deg, #fff 18.71%, hsla(0, 0%, 100%, 0));
      border-radius: 24px;
    }
    p {
      margin: 0;
    }
  }
}



@import "../section/section-text/section-text-align";

.sectiontext {
  padding: 50px 0 30px;
  margin: 0;
  .row {
    .column {
      &:nth-child(2) {
        padding-top: 40px;
      }
      h6 {
        letter-spacing: 5px;
        text-transform: uppercase;
        font-size: 1rem;
      }
      h3 {
        font-weight: 500;
        margin: 0;
      }
      p {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  &.animating-scroll {
    @include anim-indicator(from, top, 30px, 0, 0, "h6","h3","p");

    &.after-animate {
      @include anim-indicator(to, top, 0px, .3, .2, "h6","h3","p");
    }
  }
}

.graph-annual {
  position: relative;
  padding: 12px 0;
  .wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &.animating-scroll {
    @include anim-indicator(from, top, 30px, 0, 0, ".graph-annual__img", ".graph-annual__list-annual");

    &.after-animate {
      @include anim-indicator(to, top, 0px, .4, 1, ".graph-annual__img",".graph-annual__list-annual");
    }
  }
  &__img {
    width: 50%;
    padding-right: 60px;
    h6 {
      font-size: 1rem;
      letter-spacing: 5px;
      text-transform: uppercase;
      color: var(--c-primary-main);
    }
  }
  &__list-annual {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;
    &--item {
      width: 50%;
      padding: 0 14px;
      text-align: center;
      figure {
        display: block;
        overflow: hidden;
        margin-bottom: 24px;
        img {
          display: block;
          margin: 0 auto;
        }
      }
      h5 {
        margin-bottom: 24px;
        font-size: 1.25rem;
      }
    }
  }
}

.price-geo {
  padding: 12px 0 0;
  position: relative;
  h6 {
    font-size: 1rem;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: var(--c-primary-main);
  }
  .box {
    border-radius: 16px;
    border: 1px solid var(--c-neutral-white);
    background: linear-gradient(271deg, rgba(255, 255, 255, 0.51) -1.22%, #F6EFE7 107.29%);
    box-shadow: 0px 10px 30px 0px rgba(122, 76, 22, 0.10);
    padding: 68px 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    &__highlight {
      width: 330px;
      > span {
        color: var(--c-primary-main);
        display: block;
        margin-bottom: 8px;
        line-height: 160%;
        font-weight: 500;
      }
      h4 {
        line-height: 130%;
        margin-bottom: 4px;
      }
      .up-down {
        color: var(--c-success);
        display: inline-flex;
        align-items: center;
        margin-bottom: 16px;
        line-height: 180%;
        i {
          color: var(--c-neutral-white);
          background: var(--c-success);
          margin-left: 12px;
          font-size: .875rem;
        }
        &.down {
          color: var(--c-negative);
          i {
            transform: rotate(180deg);
            background: var(--c-negative);
          }
        }
      }
      small {
        display: block;
        color: var(--c-neutral-gray-2);
      }
    }
    &__list {
      width: calc(100% - 330px);
      padding-left: 78px;
      display: flex;
      flex-wrap: wrap;
      padding-top: 40px;
      &--col {
        width: calc(50% - 50px);
        margin-right: 100px;
        display: flex;
        align-items: center;
        &:nth-child(2n) {
          margin-right: 0;
        }
        span,
        label {
          margin: 0;
          display: block;
          width: 40%;
          line-height: 200%;
        }
        label {
          font-size: 15px;
          color: var(--c-neutral-gray-5);
          font-weight: 700;
        }
        span {
          font-size: 15px;
          color: var(--c-neutral-gray-3);
          width: 60%;

        }
      }
    }
  }
  &.animating-scroll {
    @include anim-indicator(from, top, 30px, 0, 0,"h6");
    .box {
      position: relative;
      opacity: 0;
      transition: all ease-in-out .4s;
      .box__highlight {
        @include anim-indicator(from, top, 30px, 0, 0, "> span", "h4", "> .up-down", "> small");
      }
      .box__list {
        &--col {
          @include anim-listing(from, top, 30px, .2, 0, 8);
        }
      }
    }

    &.after-animate {
      @include anim-indicator(to, top, 0px, 0.2, .4, "h6");
      .box {
        opacity: 1;
        transition: all ease-in-out .4s;
        transition-delay: .25s;
        .box__highlight {
          @include anim-indicator(to, top, 0px, 0.2, .4, "> span", "h4", "> .up-down", "> small");
        }
        .box__list {
          &--col {
            @include anim-listing(to, top, 0px, .15, .8, 8);
          }
        }
      }
    }
  }
}

@import "../section/section-content/section-content";

.sectioncontent-aside {
  padding: 12px 0;
  position: relative;
  top: 0;
  z-index: 5;
  &:after {
    content: '';
    position: absolute;
    top: auto;
    height: 150px;
    bottom: -150px;
    background: #191B20;
    left: 0;
    right: 0;
    display: block;
    z-index: -1;
  }
  &__content {
    .wrapper {
      padding: 0;
      .row {
        .overleaf {
          width: calc(50% + 120px);
          margin-left: -120px;
          margin-bottom: -200px;
        }
        .column-50 {
          max-width: 500px;
        }
        figure {
          &:before {
            display: none;
          }
        }
        figcaption {
          h6 {
            font-size: 1rem;
            letter-spacing: 5px;
            text-transform: uppercase;
            color: var(--c-primary-main);
          }
          h3 {
            font-weight: 500;
            line-height: 130%;
            margin-bottom: 24px;
          }
          p {
            color: var(--c-neutral-gray-3);
            line-height: 180%;
          }
        }
      }
    }
  }
  &.animating-scroll {
    .sectioncontent-aside__content {
      .wrapper {
        .row {
          .overleaf {
            opacity: 0;
            transform: scale(.95);
            transition: all ease-in-out .4s;
          }
          figcaption {
            @include anim-indicator(from, top, 30px, 0, 0, "h6", "h3", "p","> div");
          }
        }
      }
    }
    &.after-animate {
      .sectioncontent-aside__content {
          .wrapper {
          .row {
            .overleaf {
              opacity: 1;
              transform: scale(1);
              transition: all ease-in-out .4s;
              transition-delay: .3s;
            }
            figcaption {
              @include anim-indicator(to, top, 0px, .3, .5, "h6", "h3", "p", "> div");
            }
          }
        }
      }

    }
  }
}

@import "../section/section-news/section-news-aside";

.sectionnews-aside {
  padding: 180px 0 140px;
  position: relative;
  background: #191B20;
  z-index: 4;
  .bg {
    position: absolute;
    bottom: -140px;
    left: -120px;
  }
  .sectiontext {
    h6 {
      font-size: 1rem;
      letter-spacing: 5px;
      text-transform: uppercase;
      color: var(--c-primary-main);
    }
    h3 {
      font-weight: 500;
      line-height: 130%;
      margin-bottom: 24px;
      color: var(--c-neutral-white);
    }
    p {
      color: var(--c-neutral-gray-1);
      line-height: 180%;
    }
  }
  .row {
    .sectiontext {
      p {
        color: var(--c-neutral-white);
      }
    }
  }
  &__list {
    &--item {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      &:last-child {
        padding-bottom: 0;
        border: none;
        margin-bottom: 0;
      }
      .box {
        &:hover {
          figcaption {
            h5 {
              color: var(--c-primary-main);
              transition: all ease-in-out .25s;
            }
          }
        }
        figcaption {
          width: 100%;
          padding-right: 0;
          .date-user {
            color: var(--c-neutral-gray-2);
            margin-bottom: 8px;
          }
          h5 {
            font-weight: 400;
            line-height: 140%;
            color: var(--c-neutral-white);
            transition: all ease-in-out .25s;
          }
        }
      }
    }
  }
  &.animating-scroll {
    .row {
      .sectiontext {
        @include anim-indicator(from, top, 30px, 0, 0, "h6", "h3", "p", "> div");
      }
    }
    .sectionnews-aside__list--item {
      @include anim-listing(from, top, 30px, 0, 0, 8);
    }

    &.after-animate {
      .row {
        .sectiontext {
          @include anim-indicator(to, top, 0px, .3, .3, "h6", "h3", "p", "> div");
        }
      }
      .sectionnews-aside__list--item {
          @include anim-listing(to, top, 0px, .15, 1, 8);
        }
    }
  }
}



/* responsive desktop < 1400
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1383px) {
  .herobanner-full {
    &__list {
      .wrapper {
        figcaption {
          .title-large {
            font-size: 2.25rem;
          }
        }
      }
    }
  }
  .sectionnews-aside {
    &__list {
      &--item {
        .box {
          figcaption {
            h5 {
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }

}


/* responsive tablet landscape
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1200px) {
  .herobanner-full {
    &__list {
      &__text {
        max-width: 704px;
        padding: 0 40px;
      }
    }
    .swiper-pagination-bullets {
      &.swiper-pagination-horizontal {
        left: 40px;
        right: 40px;
      }
    }
    .scroll-down {
      right: 40px;
      bottom: 48px;
    }
  }
  .sectioncontent-facts {
    &__column {
      &__text {
        h6 {
          font-size: 0.75rem;
        }
        h1 {
          font-size: 1.875rem;
          padding-right: 20px;
        }
      }
    }
    &.facts-bg {
      .sectioncontent-facts__column {
        padding-bottom: 40px;
        &__text {
          h1 {
            font-size: 1.875rem;
            padding-right: 20px;
          }
        }
      }
      .key-facts {
        margin-top: 0;
        .row {
          align-items: center;
        }
        .ico-text {
          padding: 0 8px;
          &__desc {
            h4 {
              font-size: 1.625rem;
            }
            p {
              font-size: 0.938rem;
            }
          }
        }
      }
      .facts-bg__image{
        img {
          @media screen and (min-device-width: 1023px) and (max-device-width: 1200px){
            height: 80%;
          }
        }
      }
    }
  }
  .section-service-focus {
    padding: 0;
  }
  .sectionmap-center {
    #maps-app {
      height: 400px;
      .box-drag {
        transform: scale(1);
      }
      figure {
        width: 1024px;
        padding: 0 40px;
      }
    }
  }
  .sectiontext {
    .row {
      .column {
        h6 {
          font-size: 0.75rem;
        }
        &:nth-child(2){
          padding-top: 28px;
        }
      }
    }
  }
  .graph-annual {
    &__img {
      h6 {
        font-size: 0.75rem;
      }
    }
  }
  .price-geo {
    padding-bottom: 16px;
    .box {
      padding: 40px;
      &__highlight {
        width: 271px;
        > span {
          font-size: 0.938rem;
        }
        .up-down {
          margin-bottom: 74px;
        }
      }
      &__list {
        width: calc(100% - 271px);
        padding-left: 58px;
        &--col {
          width: calc(50% - 8px);
          margin-right: 16px;
          gap: 14px;
          span {
            width: 50%;
          }
          label {
            width: 50%;
          }
        }
      }
    }
  }
  .sectioncontent-aside {
    &::after {
      content: unset;
    }
    &__content {
      .wrapper {
        margin-right: 40px;
        .row {
          .overleaf {
            width: 100%;
            margin-left: 0;
            margin-bottom: -140px;
          }
          figcaption {
            h3 {
              font-size: 1.875rem;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              word-break: break-word;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  .sectionnews-aside {
    padding: 110px 0 140px;
    .sectiontext {
      h6 {
        font-size: 0.75rem;
      }
    }
    &__list {
      &--item {
        padding-bottom: 32px;
        margin-bottom: 32px;
        .box {
          figcaption {
            h5 {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

/* responsive tablet potrait
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 1023px) {
  .herobanner-full {
    &__list {
      .wrapper {
        bottom: 120px;
      }
      &__text {
        text-align: left;
      }
      .wrapper {
        figcaption {
          .title-large {
            font-size: 2.5rem;
          }
        }
      }
    }
    .swiper-pagination-bullets {
      &.swiper-pagination-horizontal {
        bottom: 68px;
        text-align: left;
      }
    }
  }
  .sectioncontent-facts {
    &.facts-bg {
      .sectioncontent-facts__column {
        padding-bottom: 220px;
      }
      .key-facts {
        .row {
          align-items: flex-start;
        }
        .ico-text {
          padding: 0;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          &__desc {
            padding-left: 0;
            h4 {
              font-size: 1.5rem;
            }
          }
        }
      }
      .facts-bg__image {
        display: flex;
        align-items: center;
        img {
          object-position: 95%;
          padding-top: 80px;
          height: 70%;
        }
      }
    }
    &__column {
      &__text {
        p {
          font-size: 0.938rem;
        }
      }
    }
  }
  .key-facts {
    .row {
      .column {
        &.column-33 {
          flex: 0 0 33.3333%;
          max-width: 33.3333%;
        }
      }
    }
  }
  .sectionmap-center {
    margin: 0 40px;
    #maps-app {
      .box-drag {
        height: 350px;
      }
      figure {
        width: 688px;
        margin: 0;
        padding: 0;
      }
      .listing-pin {
        transform: scale(0.65);
        top: -87px;
        left: -148px;
      }
    }
  }
  .sectiontext {
    padding: 30px 0;
    .row {
      .column {
        &.column-50  {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
  .graph-annual {
    &__img {
      width: 100%;
      padding: 0;
      margin-bottom: 60px;
      figure img {
        width: 100%;
      }
    }
    &__list-annual {
      width: 100%;
      padding: 0;
    }
  }
  .price-geo {
    padding: 50px 0 0;
    .box {
      &__list {
        padding-top: 0;
        flex-direction: column;
        &--col {
          width: 100%;
          margin-right: 0;
          &:nth-child(3) {
            position: relative;
            padding-bottom: 24px;
            margin-bottom: 24px;
            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background: linear-gradient(88deg, #FFF 9%, rgba(172, 114, 44, 0.15) 29.83%);
            }
          }
        }
      }
    }
  }
  .sectioncontent-aside {
    &__content {
      .wrapper {
        .row {
          .column-50 {
            max-width: 298px;
            padding: 0;
          }
          figcaption {
            p {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              word-break: break-word;
              overflow: hidden;
            }
          }
          .overleaf {
            width: calc(50% + 100px);
            margin-left: -70px;
            margin-right: -30px;
            margin-bottom: -112px;
          }
        }
      }
    }
  }
  .sectionnews-aside {
    padding: 80px 0 60px;
    .bg {
      display: none;
    }
    .row {
      width: 100%;
      margin-left: 0;
      .column {
        padding-left: 0;
      }
      .sectiontext {
        > div {
          position: absolute !important;
          right: 0;
          top: auto !important;
          bottom: 0;
          .button {
            position: static;
            right: unset;
          }
        }
      }
    }
  }
}

/* responsive mobile
----------------------------------------------------------------------------------------------*/
@media all and (max-width: 767px) {
  .bg-section {
    background: var(--c-neutral-white);
  }
  .herobanner-full {
    &__list {
      &__text {
        padding: 0;
      }
      &__image {
        img,
        video {
          height: 100svh;
          height: calc(var(--vh, 1vh)* 100);
        }
      }
      .wrapper {
        bottom: 100px;
        figcaption {
          .title-large {
            font-size: 2rem;
          }
        }
      }
    }
    .scroll-down {
      width: 48px;
      height: 48px;
      right: 16px;
      bottom: 32px;
    }
    .swiper-pagination-bullets {
      &.swiper-pagination-horizontal {
        bottom: 50px;
        text-align: center;
      }
    }
  }
  .sectioncontent-facts {
    padding: 20px 0;
    &__column__text h1 {
      font-size: 1.5rem;
    }
    &.facts-bg {
      .facts-bg__image {
        top: -33px;
        display: block;
        img {
          object-fit: contain;
          object-position: -40px;
          transform: scale(1.5) !important;
          height: 100%;
          padding-top: 0;
        }
      }
      .key-facts {
        .row {
          .column {
            margin-bottom: 24px;
            &.column-33 {
              flex: 0 0 100%;
              max-width: 100%;
            }
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
        .ico-text {
          align-items: center;
          text-align: center;
          &__desc {
            h4 {
              font-size: 1.25rem;
            }
          }
        }
      }
      .sectioncontent-facts__column{
        padding-bottom: 60px;
        &__text{
          margin-bottom: 125px;
          h1 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  .sectionmap-center {
    margin: 0;
    padding: 0;
    #maps-app {
      figure {
        transform: scale(.86);
      }
    }
    .custom-text {
      width: 100%;
      bottom: 0px;
      .wrapper{
        margin: 0;
        border-radius: 0;
      }
    }
    &__text {
      width: 100%;
      max-width: 100%;
      padding:10px 20px;
      flex-direction: column;
      gap: 20px;
      border-radius: unset;
      background-color: white;
    }
  }
  .graph-annual {
    padding-top: 32px;
    &__list-annual {
      gap: 40px;
      &--item {
        width: 100%;
        h5 {
          font-size: 1rem;
        }
        figure {
          img {
            margin: auto;
          }
        }
      }
    }
  }
  .price-geo {
    padding-bottom: 22px;
    .box {
      padding: 24px 16px;
      &__highlight {
        width: 100%;
        margin-bottom: 48px;
        .up-down {
          margin-bottom: 20px;
        }
      }
      &__list {
        width: 100%;
        padding: 0;
      }
    }
  }
  .sectioncontent-aside {
    &__content {
      .wrapper {
        margin: 0 16px;
        .row {
          flex-direction: column-reverse;
          figcaption {
            font-size: 1.5rem;
            margin-bottom: 0;
          }
          figure {
            margin-bottom: 0;
          }
          .overleaf {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
  .sectionnews-aside {
    padding-top: 0;
    overflow: hidden;
    .wrapper {
      padding-top: 200px;
      position: relative;

    }
    .bg {
      display: block;
      left: auto;
      top: 0;
      bottom: auto;
      right: -40px;
      max-width: 370px;
    }
    &__list {
      &--item {
        padding-bottom: 0;
        margin-bottom: 20px;
        .box {
          figcaption {
            h5 {
              font-size: 1rem;
            }
          }
        }
      }
    }
    .sectiontext {
      margin-bottom: 0;
    }
    .row {
      .column {
        position: static;
        &.column-50 {
          margin-bottom: 60px;
        }
      }
      .sectiontext {
        > div {
          width: 100%;
          text-align: center;
        }
        p {
          display: none;
        }
      }
    }
  }
}

@media all and (max-width: 500px) {
  .sectionmap-center {
    #maps-app {
      .box-drag {
        margin-left: 40px;
      }
    }
  }
}
@media all and (max-width: 479px) {
  .sectionmap-center {
    #maps-app {
      .box-drag {
        margin-left: 90px;
      }
    }
  }
}
